var render = function () {
  var _vm$part, _vm$part$analysisInfo, _vm$part2, _vm$part2$analysisInf, _vm$part3, _vm$part3$analysisInf, _vm$part4, _vm$part4$analysisInf, _vm$part5, _vm$part5$analysisInf;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-supplierInternalPartInformation"
  }, [_vm.part ? _c('ViewerObjectsSplitter', {
    ref: "viewerObjectsSplitterRef",
    attrs: {
      "part": _vm.part,
      "part-file": _vm.partFile
    }
  }) : _vm._e(), _c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-6",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-info-circle ")]), _c('span', [_vm._v(_vm._s(_vm.$t("PartInformation")))]), _c('v-spacer'), !_vm.formEditPartInformation ? _c('v-btn', {
    attrs: {
      "small": "",
      "color": "warning",
      "disabled": _vm.formEditPartManufacturingInformation
    },
    on: {
      "click": _vm.editPartInformation
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-pen ")])], 1) : _vm._e(), _vm.formEditPartInformation ? _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validateForm
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")])], 1) : _vm._e(), _vm.formEditPartInformation ? _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "error",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.cancelEdit
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")])], 1) : _vm._e()], 1), _c('v-card-text', [_c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "font-weight-medium width-30-percent"
        }, [_vm._v(" " + _vm._s(_vm.$t("PartName")) + " ")]), _c('td', [!_vm.formEditPartInformation ? _c('span', [_vm._v(_vm._s(_vm.part.name))]) : _c('span', [_c('v-text-field', {
          model: {
            value: _vm.formName,
            callback: function callback($$v) {
              _vm.formName = $$v;
            },
            expression: "formName"
          }
        })], 1)])]), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t("InternalReference")) + " ")]), _c('td', [!_vm.formEditPartInformation ? _c('span', [_vm._v(" " + _vm._s(_vm.part.internalReference) + " ")]) : _c('span', [_c('v-text-field', {
          model: {
            value: _vm.formInternalReference,
            callback: function callback($$v) {
              _vm.formInternalReference = $$v;
            },
            expression: "formInternalReference"
          }
        })], 1)])]), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t("Description")) + " ")]), _c('td', [!_vm.formEditPartInformation ? _c('span', [_vm._v(" " + _vm._s(_vm.part.description) + " ")]) : _c('span', [_c('v-text-field', {
          model: {
            value: _vm.formDescription,
            callback: function callback($$v) {
              _vm.formDescription = $$v;
            },
            expression: "formDescription"
          }
        })], 1)])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-6",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-industry ")]), _c('span', [_vm._v(_vm._s(_vm.$t("ManufacturingInformation")))]), _c('v-spacer'), !_vm.formEditPartManufacturingInformation ? _c('v-btn', {
    attrs: {
      "small": "",
      "color": "warning",
      "disabled": _vm.formEditPartInformation
    },
    on: {
      "click": _vm.editPartManufacturingInformation
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-pen ")])], 1) : _vm._e(), _vm.formEditPartManufacturingInformation ? _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validateForm
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")])], 1) : _vm._e(), _vm.formEditPartManufacturingInformation ? _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "error",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.cancelEdit
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")])], 1) : _vm._e()], 1), _c('v-card-text', [_c('v-card-subtitle', [_c('v-icon', {
    staticClass: "mr-2 ml-4",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-certificate ")]), _vm._v(" " + _vm._s(_vm.$t("TechnologyAndMaterial")) + " ")], 1), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('tbody', [_c('tr', {
          staticClass: "mt-5"
        }, [_c('td', {
          staticClass: "font-weight-medium width-30-percent"
        }, [_vm._v(" " + _vm._s(_vm.$t("Technology")) + " ")]), _c('td', [!_vm.formEditPartManufacturingInformation ? _c('span', [_vm._v(" " + _vm._s(_vm.$t(_vm.part.technology)) + " ")]) : _c('span', [_c('v-select', {
          attrs: {
            "items": _vm.itemsTechnology,
            "item-text": "text",
            "item-value": "value"
          },
          on: {
            "change": _vm.technologyChanged
          },
          model: {
            value: _vm.formTechnology,
            callback: function callback($$v) {
              _vm.formTechnology = $$v;
            },
            expression: "formTechnology"
          }
        })], 1)])]), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t("Material")) + " ")]), _c('td', [!_vm.formEditPartManufacturingInformation ? _c('span', [_vm._v(" " + _vm._s(_vm.$t(_vm.part.material)) + " ")]) : _c('span', [_c('v-select', {
          attrs: {
            "disabled": !_vm.formTechnology,
            "items": _vm.itemsMaterial,
            "item-text": "text",
            "item-value": "value"
          },
          model: {
            value: _vm.formMaterial,
            callback: function callback($$v) {
              _vm.formMaterial = $$v;
            },
            expression: "formMaterial"
          }
        })], 1)])])])];
      },
      proxy: true
    }])
  }), _c('v-card-subtitle', [_c('v-icon', {
    staticClass: "mr-2 ml-4",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-print ")]), _vm._v(" " + _vm._s(_vm.$t("DefaultPrinter")) + " ")], 1), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "font-weight-medium width-30-percent"
        }, [_vm._v(" " + _vm._s(_vm.$t("PrinterBrand")) + " ")]), _c('td', [!_vm.formEditPartManufacturingInformation && _vm.part.manufacturingInformations ? _c('span', [_vm._v(" " + _vm._s(_vm.part.manufacturingInformations.printer.brand.replaceAll('_', ' ')) + " ")]) : _vm._e(), _vm.formEditPartManufacturingInformation ? _c('span', [_c('v-select', {
          attrs: {
            "disabled": !_vm.formTechnology,
            "items": _vm.itemsPrinterBrand,
            "item-text": function itemText(item) {
              return item.text.replaceAll('_', ' ');
            },
            "item-value": "value"
          },
          on: {
            "change": _vm.printerBrandChanged
          },
          model: {
            value: _vm.formPrinterBrand,
            callback: function callback($$v) {
              _vm.formPrinterBrand = $$v;
            },
            expression: "formPrinterBrand"
          }
        })], 1) : _vm._e()])]), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t("PrinterModel")) + " ")]), _c('td', [!_vm.formEditPartManufacturingInformation && _vm.part.manufacturingInformations ? _c('span', [_vm._v(" " + _vm._s(_vm.part.manufacturingInformations.printer.model.replaceAll('_', ' ')) + " ")]) : _vm._e(), _vm.formEditPartManufacturingInformation ? _c('span', [_c('v-select', {
          attrs: {
            "disabled": !_vm.formPrinterBrand || !_vm.formTechnology,
            "items": _vm.itemsPrinterModel,
            "item-text": function itemText(item) {
              return item.text.replaceAll('_', ' ');
            },
            "item-value": "value"
          },
          model: {
            value: _vm.formPrinterModel,
            callback: function callback($$v) {
              _vm.formPrinterModel = $$v;
            },
            expression: "formPrinterModel"
          }
        })], 1) : _vm._e()])])])];
      },
      proxy: true
    }])
  }), _c('v-card-subtitle', [_c('v-icon', {
    staticClass: "mr-2 ml-4",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-cog ")]), _vm._v(" " + _vm._s(_vm.$t("NestingSettings")) + " ")], 1), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "font-weight-medium width-30-percent"
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("ZAxisRotation")))]), _c('v-tooltip', {
          attrs: {
            "top": "",
            "max-width": "350"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-icon', _vm._g({
                staticClass: "ml-2",
                attrs: {
                  "small": ""
                }
              }, on), [_vm._v(" fas fa-question-circle ")])];
            }
          }])
        }, [_c('v-card', {
          staticClass: "pt-0"
        }, [_c('v-card-text', {
          staticClass: "pt-0 text-justify"
        }, [_c('v-row', {
          staticClass: "pt-0"
        }, [_c('v-col', {
          staticClass: "justify-flex-around",
          attrs: {
            "cols": "12"
          }
        }, [_vm.part.zRotationAllowed ? _c('v-img', {
          attrs: {
            "src": require("../../../public/img/icons/RotationAllowed.png"),
            "contain": "",
            "aspect-ratio": "1",
            "height": "150"
          }
        }) : _c('v-img', {
          attrs: {
            "src": require("../../../public/img/icons/RotationForbidden.png"),
            "contain": "",
            "aspect-ratio": "1",
            "height": "150"
          }
        })], 1)], 1), _c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t('TooltipRotationMessage')) + " ")])], 1)], 1)], 1)], 1), _c('td', [!_vm.formEditPartManufacturingInformation ? _c('div', [_vm.part.zRotationAllowed ? _c('span', [_vm._v(_vm._s(_vm.$t('Allow')))]) : _c('span', [_vm._v(_vm._s(_vm.$t('Forbid')))])]) : _c('div', [_c('v-switch', {
          attrs: {
            "label": _vm.formRotationAllowed ? _vm.$t('Allow') : _vm.$t('Forbid')
          },
          model: {
            value: _vm.formRotationAllowed,
            callback: function callback($$v) {
              _vm.formRotationAllowed = $$v;
            },
            expression: "formRotationAllowed"
          }
        })], 1)])])])];
      },
      proxy: true
    }])
  })], 1)], 1), ((_vm$part = _vm.part) === null || _vm$part === void 0 ? void 0 : (_vm$part$analysisInfo = _vm$part.analysisInformation) === null || _vm$part$analysisInfo === void 0 ? void 0 : _vm$part$analysisInfo.outerShells) >= 2 || ((_vm$part2 = _vm.part) === null || _vm$part2 === void 0 ? void 0 : (_vm$part2$analysisInf = _vm$part2.analysisInformation) === null || _vm$part2$analysisInf === void 0 ? void 0 : _vm$part2$analysisInf.innerShells) >= 1 ? _c('v-alert', {
    attrs: {
      "type": "info",
      "prominent": "",
      "outlined": "",
      "text": ""
    }
  }, [_c('p', {
    staticClass: "text-center"
  }, [((_vm$part3 = _vm.part) === null || _vm$part3 === void 0 ? void 0 : (_vm$part3$analysisInf = _vm$part3.analysisInformation) === null || _vm$part3$analysisInf === void 0 ? void 0 : _vm$part3$analysisInf.outerShells) >= 2 && ((_vm$part4 = _vm.part) === null || _vm$part4 === void 0 ? void 0 : (_vm$part4$analysisInf = _vm$part4.analysisInformation) === null || _vm$part4$analysisInf === void 0 ? void 0 : _vm$part4$analysisInf.innerShells) >= 1 ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('PartMadeOfMultipleObjectsText1')) + " ")]) : ((_vm$part5 = _vm.part) === null || _vm$part5 === void 0 ? void 0 : (_vm$part5$analysisInf = _vm$part5.analysisInformation) === null || _vm$part5$analysisInf === void 0 ? void 0 : _vm$part5$analysisInf.outerShells) >= 2 ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('PartMadeOfMultipleObjectsText2')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('PartMadeOfMultipleObjectsText3')) + " ")])]), _c('p', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "large": ""
    },
    on: {
      "click": _vm.openViewerObjectsSplitter
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-eye ")]), _vm._v(" " + _vm._s(_vm.$t('VisualizeObjects')) + " ")], 1)], 1)]) : _vm._e(), _vm.part && _vm.part.technology && _vm.part.material && _vm.part.volume > 0 && _vm.supplier ? _c('PriceEstimationTable', {
    attrs: {
      "part": _vm.part,
      "technology": _vm.part.technology,
      "material": _vm.part.material,
      "prices-configurations": _vm.supplier.prices,
      "price-multiplier-percentage": 100
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }