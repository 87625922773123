<template>
  <div class="pa-5">
    <SupplierInternalPartOverview
      :part="part"
      :supplier-u-u-i-d="$route.params.supplierUUID"
      :supplier-part-u-u-i-d="$route.params.supplierPartUUID"
      :printers="printers"
      :part-viewer-file="partViewerFile"
      @replacePartFile="replacePartFile"
      @modifyPartCriticalDimensions="modifyPartCriticalDimensions"
    ></SupplierInternalPartOverview>
  </div>
</template>

<script>
import {
  EventBus,
  ApiErrorParser,
  DownloadSupplierInternalPartViewer3dFile,
} from '@cloudmanufacturingtechnologies/portal-components';

import SupplierInternalPartOverview from '../../../components/supplierInternalPartOverview/SupplierInternalPartOverview';

const i18nData = require('./pageInternalWarehousePartOverview.i18n');

export default {
  name: 'PageInternalWarehousePartOverview',
  components: {
    SupplierInternalPartOverview,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      part: {},
      printers: [],
      partViewerFile: null,
      viewerFullScreen: false,
    };
  },
  created() {
    EventBus.$on(
      'SupplierInternalPartFileUploaded',
      this.handleSupplierInternalPartFileUploaded
    );
    EventBus.$on('PartDeleted', this.goToWarehouseParts);
    EventBus.$on(
      'SupplierInternalPartUpdated',
      this.getSupplierInternalPart
    );
    this.getSupplierInternalPart();
    this.getSupplierPrinters();
    EventBus.$on('modifyPartOrientation', this.modifyPartOrientation);
  },
  beforeDestroy() {
    EventBus.$off(
      'SupplierInternalPartFileUploaded',
      this.handleSupplierInternalPartFileUploaded
    );
    EventBus.$off('PartDeleted', this.goToWarehouseParts);
    EventBus.$off(
      'SupplierInternalPartUpdated',
      this.getSupplierInternalPart
    );
    EventBus.$off('modifyPartOrientation', this.modifyPartOrientation);
  },
  methods: {
    modifyPartCriticalDimensions(criticalDimensions) {
      const modifySupplierInternalPartCriticalDimensionsBody = new this.$BcmModel.ModifySupplierInternalPartCriticalDimensionsBody(
        criticalDimensions
      );
      this.$apiInstance
        .modifySupplierInternalPartCriticalDimensions(
          this.$route.params.supplierUUID,
          this.$route.params.supplierPartUUID,
          modifySupplierInternalPartCriticalDimensionsBody
        )
        .then(
          (data) => {
            this.$notification.notify('SUCCESS', this.$t('SuccessCriticalDimensions'));
            this.part.criticalDimensions = data.criticalDimensions;
            this.part.analysisStatus = data.analysisStatus;
            const tmpBuffer = {...this.partViewerFile};
            this.partViewerFile = null;
            setTimeout(() => {
              this.partViewerFile = tmpBuffer;
            }, 250);
          },
          (error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error),{ timeout: 10000 }));
          }
        );
    },
    modifyPartOrientation(uuid, rotations) {
      const modifySupplierInternalPartOrientationBody = new this.$BcmModel.ModifySupplierInternalPartOrientationBody(
        rotations
      );
      this.$apiInstance
        .modifySupplierInternalPartOrientation(
          this.$route.params.supplierUUID,
          this.$route.params.supplierPartUUID,
          modifySupplierInternalPartOrientationBody
        )
        .then(
          (data) => {
            this.$notification.notify('SUCCESS', this.$t('SuccessReOrientation'));
            this.part.rotationHistory = data.rotationHistory;
            this.part.analysisStatus = data.analysisStatus;
            const tmpBuffer = {...this.partViewerFile};
            this.partViewerFile = null;
            setTimeout(() => {
              this.partViewerFile = tmpBuffer;
            }, 250);
          },
          (error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error),{ timeout: 10000 }));
          }
        );
    },
    handleSupplierInternalPartUpdated(supplierPartUUID) {
      if (supplierPartUUID === this.$route.params.supplierPartUUID) {
        // We don't want this.part to be fully updated, otherwise the viewer will reload
        // which is not necessary since we don't modify the 3D (stl) file
        // We only update the attribute that have maybe been updated
        this.$apiInstance
          .getSupplierInternalPart(
            this.$route.params.supplierUUID,
            this.$route.params.supplierPartUUID
          )
          .then((data) => {
            this.part.name = data.name;
            this.part.internalReference = data.internalReference;
            this.part.description = data.description;
            this.part.technology = data.technology;
            this.part.material = data.material;
            this.part.analysisStatus = data.analysisStatus;
            this.part.manufacturingInformations = data.manufacturingInformations;
            this.part.zRotationAllowed = data.zRotationAllowed;
          })
          .catch((error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          });
      }
    },
    getSupplierInternalPart() {
      this.part = {};
      this.$apiInstance
        .getSupplierInternalPart(
          this.$route.params.supplierUUID,
          this.$route.params.supplierPartUUID
        )
        .then((data) => {
          // We must set attributes to null otherwise Vue doesn't detect changes and thus doesn't update components
          if (!data.material) {
            data.material = null;
          }
          if (!data.technology) {
            data.technology = null;
          }
          this.part = data;

          if (!this.partViewerFile) {
            this.getSupplierInternalPartViewerFile();
          }

          if (
            this.part.analysisStatus === 'IN_PROGRESS' || this.part.analysisStatus === 'WAITING'
            || (this.part.originalFile && (this.part.fileConversionStatus === 'IN_PROGRESS' || this.fileConversionStatus === 'IDLE'))
          ) {
            setTimeout(() => {
              this.checkSupplierInternalPartAnalysisStatus(2000);
            }, 2000);
          }
        })
        .catch((error) => {
          this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
        });
    },
    getSupplierInternalPartViewerFile() {
      if (
        this.part.analysisStatus === 'WAITING' ||
        this.part.analysisStatus === 'IDLE'
      ) {
        setTimeout(this.getSupplierInternalPartViewerFile, 5000);
      } else {
        const oReq = DownloadSupplierInternalPartViewer3dFile.downloadSupplierInternalPartViewer3dFile(
          this.$apiInstance,
          this.$route.params.supplierUUID,
          this.$route.params.supplierPartUUID
        );
        oReq.onloadend = (res) => {
          this.partViewerFile = {
            extension: 'blsv',
            buffer: Buffer.from(oReq.response)
          };
        };
      }
    },
    getSupplierPrinters() {
      this.$apiInstance
        .getSupplierPrinters(this.$route.params.supplierUUID)
        .then((data) => {
          this.printers = data;
        });
    },
    checkSupplierInternalPartAnalysisStatus(nextTimeout) {
      this.$apiInstance
        .getSupplierInternalPart(
          this.$route.params.supplierUUID,
          this.$route.params.supplierPartUUID
        )
        .then((data) => {
          this.part.analysisStatus = data.analysisStatus;
          this.part.fileConversionStatus = data.fileConversionStatus;
          if (
            this.part.analysisStatus === 'IN_PROGRESS' || this.part.analysisStatus === 'WAITING'
            || (this.part.originalFile && (this.part.fileConversionStatus === 'IN_PROGRESS' || this.fileConversionStatus === 'IDLE'))
          ) {
            setTimeout(() => {
              this.checkSupplierInternalPartAnalysisStatus(
                Math.min(16000, nextTimeout * 2)
              );
            }, nextTimeout);
          }
        })
        .catch((error) => {
          this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
        });
    },
    handleSupplierInternalPartFileUploaded() {
      this.partViewerFile = null;
      this.getSupplierInternalPart();
    },
    goToWarehouseParts() {
      this.$router.push({name: 'InternalWarehouse'});
    },
    replacePartFile() {
      this.$emit('replacePartFile');
    },
  },
};
</script>
