<template lang="html" src="./supplierInternalPartOverview.template.vue">
  <!-- -->
</template>

<style lang="scss" src="./supplierInternalPartOverview.scss"></style>

<script>
import {
  SpinnerBeelse,
  Viewer3d
} from '@cloudmanufacturingtechnologies/portal-components';

import SupportEmail from '../../components/supportEmail/SupportEmail';

import SupplierInternalPartInformation from '../supplierInternalPartInformation/SupplierInternalPartInformation';

const i18nData = require('./supplierInternalPartOverview.i18n');

export default {
  name: 'SupplierInternalPartOverview',
  components: {
    SpinnerBeelse,
    SupportEmail,
    Viewer3d,
    SupplierInternalPartInformation,
  },
  props: {
    part: {
      type: Object,
      default: () => {
        return {};
      },
    },
    supplierUUID: {
      type: String,
      required: true,
    },
    supplierPartUUID: {
      type: String,
      required: true,
    },
    printers: {
      type: Array,
      required: true,
    },
    partViewerFile: {
      type: Object,
      default: null,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      viewerFullScreen: false,
    };
  },
  mounted() {},
  updated() {},
  methods: {
    switchFullscreen() {
      this.viewerFullScreen = !this.viewerFullScreen;
    },
    replacePartFile() {
      this.$emit('replacePartFile');
    },
    modifyPartCriticalDimensions(criticalDimensions) {
      this.$emit('modifyPartCriticalDimensions', criticalDimensions);
    }
  },
};
</script>
