var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-supplierInternalPartOverview"
  }, [_c('v-row', [_vm.part ? _c('SupportEmail', {
    ref: "support",
    attrs: {
      "pre-subject": "[Internal Part-".concat(_vm.part._id, "]"),
      "display-button": false
    }
  }) : _vm._e(), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": _vm.viewerFullScreen || _vm.$vuetify.breakpoint.mdAndDown ? 12 : 6
    }
  }, [_c('v-card', {
    staticClass: "elevation-0"
  }, [_c('v-card-text', {
    staticClass: "text-center justify-center px-0 pt-2"
  }, [_vm.part.originalFile && _vm.part.fileConversionStatus !== 'DONE' ? _c('div', {
    staticClass: "pa-12"
  }, [_vm.part.fileConversionStatus !== 'ERROR' ? _c('div', [_c('SpinnerBeelse', {
    attrs: {
      "multiple": "",
      "color": "orange",
      "logo": "simple",
      "size": 150,
      "display-logo": false
    }
  }), _c('p', {
    staticClass: "headline mt-8"
  }, [_vm._v(" " + _vm._s(_vm.$t('STEPMeshingInProgress')) + " ")]), _c('p', {
    staticClass: "subtitle-1 mb-12"
  }, [_vm._v(" " + _vm._s(_vm.$t('ThisCanTakeFewMinutes')) + " ")])], 1) : _c('div', [_c('v-alert', {
    staticClass: "headline mb-12",
    attrs: {
      "type": "error"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('STEPMeshingErrorTitle')) + " ")])]), _c('p', {
    staticClass: "mb-2 subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('STEPMeshingErrorText1')) + " ")]), _c('p', {
    staticClass: "mb-8 subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('FileErrorText1')) + " ")]), _c('p', {
    staticClass: "headline mb-12"
  }, [_c('v-btn', {
    staticClass: "text-none mr-4",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.$refs.support.dialogSupportEmail = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-headset ")]), _vm._v(" " + _vm._s(_vm.$t('ContactSupport')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none white--text",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": _vm.replacePartFile
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-upload ")]), _vm._v(" " + _vm._s(_vm.$t('UploadANew3dFile')) + " ")], 1)], 1)], 1)]) : _vm.partViewerFile ? _c('div', {
    staticClass: "pt-3"
  }, [_c('Viewer3d', {
    attrs: {
      "part-file": _vm.partViewerFile,
      "part": _vm.part,
      "information-control-enabled": true,
      "mesh": "#a0a0a0",
      "mass-tinted": "#a0a0a0",
      "background": "#ffffff",
      "toolbar": true,
      "clipping": true,
      "reorientation-tool": true,
      "initial-fullscreen": _vm.viewerFullScreen,
      "can-modify-critical-dimensions": true,
      "critical-dimensions-enabled": true
    },
    on: {
      "fullscreen": _vm.switchFullscreen,
      "modifyPartCriticalDimensions": _vm.modifyPartCriticalDimensions
    }
  })], 1) : _c('div', {
    staticClass: "pa-12"
  }, [_vm.part.analysisStatus !== 'FAILED' ? _c('div', [_c('SpinnerBeelse', {
    attrs: {
      "multiple": "",
      "color": "orange",
      "logo": "simple",
      "size": 150,
      "display-logo": false
    }
  }), _vm.part.analysisStatus !== 'DONE' ? _c('p', {
    staticClass: "headline mb-12 mt-8"
  }, [_vm._v(" " + _vm._s(_vm.$t('AnalysisInProgress')) + " ")]) : _vm._e()], 1) : _c('div', [_c('v-alert', {
    staticClass: "headline mb-12",
    attrs: {
      "type": "error"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('AnalysisFailed')) + " ")])]), _c('p', {
    staticClass: "mb-2 subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('AnalysisFailedText1')) + " ")]), _c('p', {
    staticClass: "mb-8 subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('FileErrorText1')) + " ")]), _c('p', {
    staticClass: "headline mb-12"
  }, [_c('v-btn', {
    staticClass: "text-none mr-4",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.$refs.support.dialogSupportEmail = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-headset ")]), _vm._v(" " + _vm._s(_vm.$t('ContactSupport')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none white--text",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": _vm.replacePartFile
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-upload ")]), _vm._v(" " + _vm._s(_vm.$t('UploadANew3dFile')) + " ")], 1)], 1)], 1)])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": _vm.$vuetify.breakpoint.mdAndDown ? 12 : 6
    }
  }, [_vm.part && _vm.printers ? _c('SupplierInternalPartInformation', {
    attrs: {
      "part": _vm.part,
      "supplier-u-u-i-d": _vm.$route.params.supplierUUID,
      "supplier-part-u-u-i-d": _vm.$route.params.supplierPartUUID,
      "printers": _vm.printers
    }
  }) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }