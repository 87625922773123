var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-5"
  }, [_c('SupplierInternalPartOverview', {
    attrs: {
      "part": _vm.part,
      "supplier-u-u-i-d": _vm.$route.params.supplierUUID,
      "supplier-part-u-u-i-d": _vm.$route.params.supplierPartUUID,
      "printers": _vm.printers,
      "part-viewer-file": _vm.partViewerFile
    },
    on: {
      "replacePartFile": _vm.replacePartFile,
      "modifyPartCriticalDimensions": _vm.modifyPartCriticalDimensions
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }