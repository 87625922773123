<template lang="html" src="./supplierInternalPartInformation.template.vue"></template>

<style lang="scss" src="./supplierInternalPartInformation.scss"></style>

<script>
import { EventBus, ApiErrorParser, DownloadSupplierInternalPartFile, ViewerObjectsSplitter } from '@cloudmanufacturingtechnologies/portal-components';
import PriceEstimationTable from '../priceEstimationTable/PriceEstimationTable';

const i18nData = require('./supplierInternalPartInformation.i18n');

export default {
  name: 'SupplierInternalPartInformation',
  props: {
    part: {
      type: Object,
      default: () => {
        return {};
      },
    },
    supplierUUID: {
      type: String,
      required: true,
    },
    supplierPartUUID: {
      type: String,
      required: true,
    },
    printers: {
      type: Array,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  components: {
    PriceEstimationTable,
    ViewerObjectsSplitter
  },
  data() {
    return {
      loading: false,
      needsUpdate: false,
      formEditPartInformation: false,
      formEditPartManufacturingInformation: false,
      formName: '',
      formInternalReference: '',
      formDescription: '',
      formTechnology: '',
      formMaterial: '',
      formPrinterBrand: '',
      formPrinterModel: '',
      formRotationAllowed: true,
      allCompatibilities: [],
      itemsTechnology: [],
      itemsMaterial: [],
      itemsPrinterBrand: [],
      itemsPrinterModel: [],
      renderKey: 0,
      supplier: null,
      partFile: null
    };
  },
  created() {
    this.itemsTechnology.push({
      text: this.$t('Unspecified'),
      value: null,
    });
    this.getAllTechnologiesAndMaterials();
    this.getSupplier();
  },
  updated() {
    if (this.needsUpdate) {
      this.needsUpdate = false;
      this.resetForms();
      this.initPrinterBrandAndModelForms();
    }
  },
  methods: {
    getSupplier() {
      this.$apiInstance.getSupplier(this.$route.params.supplierUUID)
        .then(supplier => {
          this.supplier = supplier;
        })
        .catch((error) => {
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        });
    },
    getAllTechnologiesAndMaterials() {
      this.$apiInstance
        .getAllTechnologiesAndMaterialsCompatibilities()
        .then((result) => {
          this.allCompatibilities = result;
          for (const technologyObj of result) {
            this.itemsTechnology.push({
              text: this.$t(technologyObj.technology),
              value: technologyObj.technology,
            });
          }
          this.resetForms();
          this.initPrinterBrandAndModelForms();
        });
    },
    openViewerObjectsSplitter() {
      this.getPartFileForViewerSplitter();
      this.$refs.viewerObjectsSplitterRef.open();
    },
    getPartFileForViewerSplitter() {
      if(!this.partFile) {
        DownloadSupplierInternalPartFile.downloadSupplierInternalPartFile(
          this.$apiInstance,
          this.$route.params.supplierUUID,
          this.$route.params.supplierPartUUID,
          'stl'
        )[0].then((response) => {
          this.partFile = {
            extension: response.extension,
            buffer: Buffer.from(response.buffer),
          };
        });
      }
    },
    resetForms() {
      this.formEditPartInformation = false;
      this.formEditPartManufacturingInformation = false;
      this.formName = this.part.name;
      this.formInternalReference = this.part.internalReference;
      this.formDescription = this.part.description;
      this.formTechnology = this.part.technology;
      this.formPrinterBrand = this.part.manufacturingInformations
        ? this.part.manufacturingInformations.printer.brand
        : null;
      this.formPrinterModel = this.part.manufacturingInformations
        ? this.part.manufacturingInformations.printer.model
        : null;
      this.formRotationAllowed = this.part.zRotationAllowed;
      this.updateMaterialItems();
      this.updatePrinterBrandItems();
      this.updatePrinterModelItems();
      this.formMaterial = this.part.material;
    },
    editPartInformation() {
      this.resetForms();
      this.formEditPartInformation = true;
    },
    editPartManufacturingInformation() {
      this.resetForms();
      this.formEditPartManufacturingInformation = true;
    },
    cancelEdit() {
      this.resetForms();
    },
    technologyChanged() {
      this.formPrinterBrand = null;
      this.updateMaterialItems();
      this.updatePrinterBrandItems();
      this.updatePrinterModelItems();
    },
    updateMaterialItems() {
      this.itemsMaterial = [];
      this.itemsMaterial.push({
        text: this.$t('Unspecified'),
        value: null,
      });
      for (const technologyObj of this.allCompatibilities) {
        if (technologyObj.technology === this.formTechnology) {
          for (const materialObj of technologyObj.materials) {
            this.itemsMaterial.push({
              text: this.$t(materialObj.material),
              value: materialObj.material,
            });
          }
          break;
        }
      }
    },
    updatePrinterBrandItems() {
      this.itemsPrinterBrand = [];
      this.itemsPrinterBrand.push({
        text: this.$t('Unspecified'),
        value: null,
      });
      const printerBrandSet = new Set();
      for (const printer of this.printers) {
        if (
          printer.technology === this.formTechnology &&
          !printerBrandSet.has(printer.brand)
        ) {
          printerBrandSet.add(printer.brand);
          this.itemsPrinterBrand.push({
            text: printer.brand,
            value: printer.brand,
          });
        }
      }
    },
    updatePrinterModelItems() {
      this.itemsPrinterModel = [];
      for (const printer of this.printers) {
        if (
          printer.technology === this.formTechnology &&
          printer.brand === this.formPrinterBrand
        ) {
          this.itemsPrinterModel.push({
            text: printer.model,
            value: printer.model,
          });
        }
      }
    },
    initPrinterBrandAndModelForms() {
      this.formPrinterBrand = null;
      this.formPrinterModel = null;
      this.updatePrinterBrandItems();
      this.updatePrinterModelItems();
      if (this.part.manufacturingInformations) {
        this.formPrinterBrand = this.part.manufacturingInformations.printer.brand;
        this.formPrinterModel = this.part.manufacturingInformations.printer.model;
      }
    },
    printerBrandChanged() {
      this.updatePrinterModelItems();
      if (this.formPrinterBrand !== null) {
        this.formPrinterModel = this.itemsPrinterModel[0].value;
      } else {
        this.formPrinterModel = null;
      }
    },
    validateForm() {
      this.loading = true;
      const modifySupplierInternalPartBody = new this.$BcmModel.ModifySupplierInternalPartBody(
        this.formName,
        this.formDescription,
        this.formInternalReference,
        this.formRotationAllowed
      );

      if (this.formTechnology) {
        modifySupplierInternalPartBody.technology = this.formTechnology;
        if (this.formMaterial) {
          modifySupplierInternalPartBody.material = this.formMaterial;
        }
      }

      if (this.formPrinterBrand) {
        if (!this.formPrinterModel) {
          this.$notification.notify('DANGER',this.$t('MustChoseModel'));
          return;
        }
        modifySupplierInternalPartBody.printerBrand = this.formPrinterBrand;
        modifySupplierInternalPartBody.printerModel = this.formPrinterModel;
      }

      this.$apiInstance
        .modifySupplierInternalPart(
          this.supplierUUID,
          this.supplierPartUUID,
          modifySupplierInternalPartBody
        )
        .then(
          () => {
            this.needsUpdate = true;
            this.$notification.notify('SUCCESS',this.$t('PartUpdated'));
            EventBus.$emit('SupplierInternalPartUpdated', this.supplierPartUUID);
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
